import { Tooltip } from 'antd';
import Table from 'app/components/Table';
import { useGetAllInfluencer } from 'hooks/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ListingProps {
  searchValue: string;
}
export const Listing: React.FC<ListingProps> = ({ searchValue }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading, refetch } = useGetAllInfluencer({
    pageSize,
    currentPage,
    username: searchValue,
  });

  useEffect(() => {
    refetch();
  }, [currentPage, pageSize, searchValue]);
  const filteredData = data?.data?.filter((record: any) => {
    return record?.username.toLowerCase().includes(searchValue.toLowerCase());
  });
  const columns = [
    {
      title: t('columns_header.order_no'),
      dataIndex: 'order_no',
      key: 'order_no',
      render: (text: string) => {
        return (
          <Tooltip title={text?.length > 18 ? text : ''}>
            <span className="cursor-pointer">
              {text?.length > 18 ? `${text.substring(0, 18)}...` : text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: t('columns_header.influencer_name'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('columns_header.total_paid'),
      dataIndex: 'total_paid',
      key: 'total_paid',
      render: (text: any) => `€${text ?? 0}`,
    },
    {
      title: t('columns_header.amount_earned'),
      dataIndex: 'commission',
      key: 'commission',
      render: (text: any) => `€${text}`,
    },
  ];

  return (
    <Table
      columns={columns}
      data={filteredData}
      pagination
      pageSize={pageSize}
      total={data?.total || 0}
      currentPage={currentPage}
      skeletonRowCount={pageSize}
      isLoading={isLoading}
      onPageChange={(page, size) => {
        setCurrentPage(page);
        if (size) setPageSize(size);
      }}
    />
  );
};
